/**
 * Summer Sweepstakes
 */
import React from 'react'
import tw from 'tailwind.macro'
import { Layout } from '../templates'
import {
  Body,
  Display,
} from '../components/RichText'
import SEO from '../templates/Layout/SEO'

export default () => (
  <Layout>
    <SEO
      title="Accolade Sweepstakes"
      description="Accolade Summer Sweepstakes Terms and Conditions"
    />
    <div css={tw`flex justify-center`}>
      <section css={tw`flex-col py-12 w-4/5`}>
        <Display css={tw`text-4xl font-semibold pb-1`}>
          Accolade Sweepstakes
        </Display>
        {/* <div css={mq({ margin: ['1rem 1rem', '1rem 6rem'] })}>
      <TitleLarge>Accolade Sweepstakes</TitleLarge> */}
        <Body>
          <strong>NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCE OF WINNING.</strong>
        </Body>
        <Body>
          Please read these Official Rules before entering a giveaway (each a "Sweepstakes"). You agree that, by submitting an entry, you will be bound by these Official Rules and you acknowledge that you satisfy all Sweepstakes eligibility requirements.
        </Body>
        <Body>
          <strong>Eligibility.</strong> Open to individual legal residents of the 50 United States or the District of Columbia who are the older of 18 years of age or the legal age of majority in their state of residence, except that if the total approximate retail value ("ARV") of all prizes in the Sweepstakes exceeds $500.00, individual legal residents of Rhode Island will not be eligible to enter.  Additionally, the Sweepstakes is only open to individuals who directly received an email or online invitation to participate in the Sweepstakes survey.  Employees of Accolade, Inc. (“Sponsor") and their respective affiliate, subsidiary and parent companies, suppliers as well as the immediate family (spouse, parents, siblings and children) and household members of each such employee are not eligible.  The Sweepstakes is subject to all applicable federal, state, provincial, territorial, municipal, and local laws and regulations and is void where prohibited.
        </Body>
        <Body>
          <strong>Sponsor.</strong> Accolade ("we" or "us"), is the entity offering the Sweepstakes on the Qualtrics survey site. <em>The Sweepstakes is in no way sponsored, endorsed or administered by, or associated with any product or service through which videos may be played, including but not limited to Qualtrics. If you are accessing the Sweepstakes through a mobile app, the mobile device platform provider (such as Apple or Google) is not a sponsor of the Sweepstakes.</em>
        </Body>
        <Body>
          <strong>Your Personal Information.</strong> By entering the Sweepstakes (i.e. submitting an Entry), you expressly consent to the use of the personal information, such as your name and email address, you provide in your Entry or otherwise being used by Sponsor (a) for the purposes of administering the Sweepstakes and prize fulfillment; (b) as required by law or legal process, and (c) otherwise as permitted by Sponsor’s privacy policy, available here:{' '} <a href="https://www.accolade.com/privacy-policy/">https://www.accolade.com/privacy-policy/</a>, which terms are incorporated into these Rules by reference.
        </Body>
        <Body>
          <strong>Timing: </strong>
          The Sweepstakes begins on July 26<sup>th</sup>, 2021, at 12:00 a.m. United States Eastern Time ("ET") and ends on August, 15<sup>th</sup>, 2021 at 11:59 p.m. ET (the "Sweepstakes Period").
        </Body>
        <Body>
          <strong>How to Enter.</strong> You may enter a Sweepstakes via one of the entry methods below:
        </Body>
        <Body>
          <u>Survey Entry:</u>
        </Body>
        <Body>
          During the Promotion Period be invited by Sponsor, follow the link to the survey website, and complete the survey online. Once at the survey, you should complete the questions provided. Entrants must complete all required information. Click “Done” to submit your answers and submit your entry into the Sweepstakes random drawing.  After you have completed the survey, you will also receive one (1) entry into the Prize Drawing. The length of time required to complete a survey will vary from approximately one (1) to three (3) minutes.  LIMIT ONE (1) SURVEY ENTRY PER PERSON / E-MAIL ADDRESS / COMPLETED SURVEY ASSOCIATED WITH THIS PROMOTION.
        </Body>
        <Body>
          <u>Mail-in Entry:</u>
        </Body>
        <Body>
          You may enter the Promotion by printing (in the following order) your full name, work e-mail address, work street address, city, state, zip code, and telephone number on a standard 4′′ x 6′′ postcard and mail it to: 660 W Germantown Pike, Plymouth Meeting, PA 19462 (“Mail-in Entry”).  Mail-in Entries must be postmarked within the Promotion Period and received within 7 days of the end of the Promotion Period. Each Mail-in Entry must be mailed separately. A representative of Sponsor will play an instant win game with the same odds and prizes on your behalf for every valid Mail-in Entry received. You will also receive one (1) entry into the Prize Drawing.  LIMIT ONE (1) MAIL-IN ENTRY PER PERSON / E-MAIL ADDRESS / POSTCARD.
        </Body>
        <Body>
          You may not use multiple Survey Submission to enter a Sweepstakes, and an entrant who submits entries into a Sweepstakes through multiple accounts may be disqualified. To preserve the standards of a Sweepstakes, Accolade may occasionally ask for additional information which will be used for fraud prevention.
        </Body>
        <Body>
          <strong>Prize and Winner Selection Method.</strong> Accolade will award 28 prizes to 28 individuals who enter the Sweepstakes as specified at the Sweepstakes point of entry ("Accolade Summer Sweepstakes" email).  One Potential winner will be selected by random drawing from among all eligible entries received. Odds of winning depend on the number eligible entries received.  The random drawing will be conducted on or about August 19<sup>th</sup>, 2021 by Accolade.  After the Sweepstakes Period, Accolade will notify each potential winner via e-mail to the e-mail address associated with the potential winner’s submission, and the potential winner must claim the prize within seventy-two (72) business day hours. An alternative potential winner may be selected if any potential winner does not claim the prize within seventy-two (72) business day hours of notification.  The Sponsor is not responsible for changes to winner’s email that may interfere with the winner notification.
        </Body>
        <Body>
          Entrants must submit their answers to the survey in order to enter or receive the prize. Limit one prize per person. Prizes will be shipped by Accolade. Winner will be responsible for providing or confirming a valid U.S. shipping address prior to the prize being shipped.
        </Body>
        <Body>
          All federal, state and local income taxes on the prizes and all other costs associated with acceptance or use of the prizes are the sole responsibility of the winners.
        </Body>
        <Body>
          Total ARV of all prizes available in this Promotion is $300 USD. Each prize will be awarded "AS IS" and WITHOUT WARRANTY OF ANY KIND, express or implied (including, without limitation, any implied warranty of merchantability or fitness for a particular purpose). If you win a prize, you may not transfer, assign, or redeem the prize for cash. Accolade reserves the right to substitute a prize with a gift card or other prize of value equal to or greater than the value paid by Accolade while setting up the Sweepstakes.
        </Body>
        <Body>
          <strong>Other Promotions.</strong> By entering a Sweepstakes, you will not be eligible to receive any prizes awarded in any other Sweepstakes unless you enter each Sweepstakes separately.
        </Body>
        <Body>
          <strong>Additional Terms.</strong> Accolade reserves the right to modify or cancel a Sweepstakes at any time.
        </Body>
        <Body>
          Accolade is not responsible for: (a) lost, misdirected, late, incomplete, or unintelligible entries or for inaccurate entry information, whether caused by you or by any of the equipment or programming associated with or utilized in a Sweepstakes, or by any technical or human error that may occur in the processing of entries; (b) any printing or typographical errors in any materials associated with a Sweepstakes; (c) any error in the operation or transmission, theft, destruction, unauthorized access to, or alteration of, entries, or for technical, network, telephone, computer, hardware or software, malfunctions of any kind, or inaccurate transmission of, or failure to receive any entry information on account of technical problems or traffic congestion on the Internet or at any website; or (d) injury or damage to your or any other computer or device resulting from downloading any materials in connection with a Sweepstakes.
        </Body>
        <Body>
          Accolade may, in its sole discretion, disqualify any individual found to be: (x) tampering with the entry process or the operation of a Sweepstakes or website; (y) acting in violation of these rules; or (z) acting in an unsportsmanlike or disruptive manner or with intent to annoy, abuse, threaten or harass any other person. If your entry is incomplete or if you use robotic, automatic, programmed or similar entry methods, your entry will be disqualified.
        </Body>
        <Body>
          The authorized subscriber of the e-mail account used to enter a Sweepstakes at the actual time of entry will be deemed to be the participant and must comply with these rules in the event of a dispute as to entries submitted by multiple users having the same e-mail account. The authorized account subscriber is the natural person who is assigned an e-mail address by an Internet access provider, on-line service provider, or other organization responsible for assigning e-mail addresses for the domains associated with the submitted e-mail addresses.
        </Body>
        <Body>
          No greater than the 1 of the prize stated will be awarded, and if the prize is not claimed seventy-two (72) business day hours after the end date of the Sweepstakes Period, the prize may not be awarded and such prize may be forfeited. If any technical or other errors occur in the selection of a potential winner, Accolade may award the prize(s) by random drawing from among all eligible claimants.
        </Body>
        <Body>
          <strong>Miscellaneous.</strong> All applicable federal, state and local laws apply. By entering a Sweepstakes, you agree (for yourself and your heirs) that Accolade, along with each of their respective affiliates and agents, and any entity involved in any aspect of the Sweepstakes (all aforementioned parties are collectively, the "Released Parties") will have no liability, and will be held harmless from and against liability, loss, injury or damage to property or person, including death, and reasonable attorney's fees and court costs, due in whole or in part, directly or indirectly, by reason of the acceptance, possession, use or misuse of a prize or participation in the Sweepstakes, even if caused or contributed to by the negligence of any of the Released Parties.
        </Body>
        <Body>
          You agree that Accolade’s decisions related to the Sweepstakes and all distribution of prizes are final. All participants and the winner participating in the Sweepstakes will not have their name used in any manner and in any medium for promotional purposes without additional authorization from the parties involved, including posting in a winner's list, except where prohibited by law, and Accolade may be required to disclose the winner’s Survey Submission information to state regulatory authorities. Outside this, the winner will not be announced.
        </Body>
        <Body>
          <strong>Choice of Law and Jurisdiction:</strong> Except where prohibited, all issues and questions concerning the construction, validity, interpretation, and enforceability of these Official Rules, or the rights and obligations of entrants or winners, Sponsor, and administrator in connection with this Promotion, shall be governed by, and construed in accordance with, the laws of the State of Delaware, without giving effect to any choice of law or conflict of law rules or provisions (whether of the State of Delaware or any other jurisdiction), which might otherwise cause the application of the laws of any jurisdiction other than the State of Delaware. Any action seeking legal or equitable relief arising out of or relating to the Promotion or these Official Rules shall be brought only in the courts of the State of Delaware. You hereby irrevocably consent to the personal jurisdiction of said courts and waive any claim of forum non-convenience or lack of personal jurisdiction they may have.
        </Body>
        <Body>
          The invalidity or unenforceability of any provision of these Official Rules shall not affect the validity or enforceability of any other provision. In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Official Rules shall otherwise remain in effect and shall be construed in accordance with their terms as if the invalid or illegal provision were not contained herein.
        </Body>
        <Body>
          <strong>All trademarks used herein are the property of their respective owners.</strong>
        </Body>
      </section>
    </div>
  </Layout>
)
